import React from "react";


export default function Tailwind() {

  return (
    <main className="dark:bg-black dark:text-white container mx-auto py-12">
      <section>
        <h1 className="cursive text-6xl flex justify-center">Tailwind</h1>
        <h2 className="text-lg text-gray-600 flex justify-center mb-12">A collection of some of my favorite Tailwind components and styles.</h2>
      </section>
      <section className="">
        <h3 className="cursive text-3xl my-6">Component 1</h3>
        <div>Component Here</div>
      </section>
      <section>
        <h3 className="cursive text-3xl my-6">Component 2</h3>
        <div>Component Here</div>
      </section>
      <section>
        <h3 className="cursive text-3xl my-6">Component 3</h3>
        <div>Component Here</div>
      </section>
      <section>
        <h3 className="cursive text-3xl my-6">Component 4</h3>
        <div>Component Here</div>
      </section>
    </main>
  )
}