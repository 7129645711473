import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import fiddleFig from "../img/fiddle-fig.jpg";
import BlockContent from "@sanity/block-content-to-react";

export default function About() {
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "author"]{
      name,
      bio,
      "authorImage": image.asset->url
    }`)
    .then((data) => setAuthor(data[0]))
    .catch(console.error)
  }, []);

  if (!author) return <div>Loading...</div>

  return (
    <main className="relative">
      <img src={fiddleFig} alt="Fiddle Fig" className="absolute w-full min-h-screen object-cover"/>
      <div className="p-10 lg:pt-48 container mx-auto relative">
        <section className="bg-green-800 rounded-lg shadow-2xl lg:flex p-20">
          <img src={author.authorImage} alt={author.name} className="rounded w-32 lg:w-64 mr-8"/>
          <div className="text-lg flex flex-col justify-center">
            <h1 className="cursive text-6xl text-green-300 mb-4">
              Howdy, I'm{" "}
              <span className="text-green-100">{author.name}</span>
            </h1>
            <div className="prose lg:prose-xl text-white">
              <BlockContent blocks={author.bio} projectId="7mhnvi1z" dataSet="production" />
            </div>
          </div>
        </section>
      </div>
    </main>  
  )
}